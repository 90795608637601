export const TEST_IDS = {
  LOADING: 'LOADING',
  FAVICON: 'FAVICON',
  FAVICON_SKELETON: 'FAVICON_SKELETON',
  FAVICON_FALLBACK: 'FAVICON_FALLBACK',
  IMAGE: 'IMAGE',
  IMAGE_SKELETON: 'IMAGE_SKELETON',
  IMAGE_FALLBACK: 'IMAGE_FALLBACK',
  AUTHOR: 'AUTHOR',
  DATE: 'DATE',
  ORIGIN: 'PUBLISHER',
  ANCHOR_ELEMENT: 'ANCHOR_ELEMENT',
  TITLE: 'TITLE',
  TITLE_SKELETON: 'TITLE_SKELETON',
  DESCRIPTION: 'DESCRIPTION',
  READ_MORE_BUTTON: 'READ_MORE_BUTTON',
  POWERED_BY: 'POWERED_BY',
};

export function byTestId(testId: string) {
  return `[data-testid="${testId}"]`;
}
